import BaseComponent from "../../BaseComponent";
import IComponent from "../../IComponent";
import DashboardState from "./model/DashboardState";
import QuestionnairesStartsStatus from "./dashboardComponents/QuestionnairesStartsStatus";
import QuestionnairesStarts from "./dashboardComponents/QuestionnairesStarts";
import QuestionnaireLast from "./dashboardComponents/QuestionnaireLast";
import DataRefresh from "./dashboardComponents/DataRefresh";
import UserDevices from "./dashboardComponents/UserDevices";
import UserBrowsers from "./dashboardComponents/UserBrowsers";
import Step from "./dashboardComponents/Step";
import QuestionsDuration from "./dashboardComponents/QuestionsDuration";
import QuestionsStatuses from "./dashboardComponents/QuestionsStatuses";
import QuestionsThroughput from "./dashboardComponents/QuestionsThroughput";
import QuestionsThroughputStatuses from "./dashboardComponents/QuestionsThroughputStatuses";
import Overview from "./dashboardComponents/Overview";
import CommonBar from "./dashboardComponents/CommonBar";
import AnswersHistogram from "./dashboardComponents/AnswersHistogram";
import AnswersHistogramStatusSwitcher from "./dashboardComponents/AnswersHistogramStatusSwitcher";
import {components} from "../../../app";
import IDashboardComponent from "./dashboardComponents/IDashboardComponent";
import FeedbackOverview from "./dashboardComponents/FeedbackOverview";

export default class ProjectDashboard extends BaseComponent implements IComponent {
    private dashboardState: DashboardState;
    private components: IDashboardComponent[] = [];

    init(): void {
        const apiUrl = this.$component.data('url');
        this.dashboardState = DashboardState.getInstance(apiUrl);
        this.registerDashboardComponents();
        this.dashboardState.update();
    }
    

    private registerDashboardComponents(): void {
        this.$component.find('[data-dashboard-component]').each((i,el) => {
            const $el = $(el);
            const name = $el.data('dashboard-component');
            switch (name) {
                case 'DataRefresh': this.components.push(new DataRefresh(el, this.dashboardState)); break;
                case 'overview': this.components.push(new Overview(el, this.dashboardState, 'overview')); break;
                case 'feedback.overview': this.components.push(new FeedbackOverview(el)); break;
                case 'questions.durations': this.components.push(new QuestionsDuration(el)); break;
                case 'questions.statuses': this.components.push(new QuestionsStatuses(el)); break;
                case 'questions.throughput': this.components.push(new QuestionsThroughput(el)); break;
                case 'questions.throughput.statuses': this.components.push(new QuestionsThroughputStatuses(el)); break;
                case 'questionnaires.starts.status': this.components.push(new QuestionnairesStartsStatus(el)); break;
                case 'questionnaires.starts.step': this.components.push(new Step(<HTMLSelectElement>el, this.dashboardState, "questionnaires.starts")); break;
                case 'questionnaires.starts': this.components.push(new QuestionnairesStarts(el)); break;
                case 'questionnaire.last': this.components.push(new QuestionnaireLast(el)); break;
                case 'users.devices': this.components.push(new UserDevices(el)); break;
                case 'users.browsers': this.components.push(new UserBrowsers(el)); break;
                case 'users.deviceBrowserTrend': this.components.push(new CommonBar(el, 'users.deviceBrowserTrend')); break;
                case 'users.deviceGroupTrend': this.components.push(new CommonBar(el, 'users.deviceGroupTrend')); break;
                case 'users.prefersColorSchemeTrend': this.components.push(new CommonBar(el, 'users.prefersColorSchemeTrend')); break;
                case 'question.answers.histogram': this.components.push(new AnswersHistogram(el, this.dashboardState,'question.answers.histogram')); break;
                case 'question.answers.histogram.statuses': this.components.push(new AnswersHistogramStatusSwitcher(<HTMLSelectElement>el, this.dashboardState)); break;
                default: console.warn(`Dashboard component '${name}' not found.`);
           }
        });

        this.dashboardState.addOnUpdate((state, instance, data) => {
            let componentsToUpdate: IDashboardComponent[];
            if (Array.isArray(data)) {
                componentsToUpdate = this.components.filter( component => data.includes(component.name()) );
            } else {
                componentsToUpdate = this.components;
            }

            componentsToUpdate.forEach(component => component.update(state));
        });
    }

}
