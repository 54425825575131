import m from 'mithril';

type TPermission = {
    read: boolean,
    create: boolean,
    modify: boolean,
}

export type TTagsConfig = {
    tagging: {
        namePattern: string,
        namePatternDescription: string,
        htmlClassList: {
            classes: string[],
            blacklist: string,
            duplicity: string,
        },
        permissions: {
            blacklist: TPermission,
            duplicity: TPermission,
        },
        dictionary: {[key: string]: string},
    }
};

export type TTag = {
    id?: number,
    search: string,
    name: string,
    htmlClass: string,
    isPrivate: boolean,
    checkBlacklist: boolean,
    checkDuplicity: boolean,
    deletable: boolean,
    editable: boolean,
}

export type TProject = {
    id: number,
    name: string,
}

type TProjectId = number|'favourite';


class TagApi {

    private static url_config(): string {
        return '/management/api/config';
    }

    private static url_tag(tagId?: number): string {
        return '/management/api/tag' + (tagId ? '/'+tagId : '');
    }

    private static url_tags(limit = 30, offset = 0): string {
        return `/management/api/tags/all/${limit}/${offset}`;
    }

    private static url_tag_projects(tagId: number): string {
        return `/management/api/tag/${tagId}/projects`;
    }

    private static url_assignment(tagId: number, projectId: TProjectId): string {
        if (projectId === 'favourite') {
            return TagApi.url_favourite(tagId);
        }
        return `/management/api/tag/${tagId}/assignment/${projectId}`;
    }

    private static url_favourite(tagId: number):string {
        return `/management/api/tag/${tagId}/favorite`;
    }


    public config(): Promise<TTagsConfig> {
        return m.request({
            url: TagApi.url_config(),
            method: 'GET',
        });
    }

    public tags(limit = 30, offset = 0): Promise<TTag[]> {
        return m.request({
            url: TagApi.url_tags(limit, offset),
            method: 'GET',
        });
    }

    public tag(tagId: number): Promise<TTagsConfig> {
        return m.request({
            url: TagApi.url_tag(tagId),
            method: 'GET',
        });
    }

    public save(tag: TTag): Promise<TTag> {
        return m.request({
            url: TagApi.url_tag(tag.id),
            method: tag.id ? 'PUT' : 'POST',
            body: tag,
        });
    }

    public delete(tagId: number): Promise<void> {
        return m.request({
            url: TagApi.url_tag(tagId),
            method: 'DELETE',
        });
    }

    public projects(tagId: number): Promise<TProject[]> {
        return m.request({
            url: TagApi.url_tag_projects(tagId),
            method: 'GET',
        });
    }

    public assign(tagId: number, projectId: TProjectId): Promise<void> {
        return m.request({
            url: TagApi.url_assignment(tagId, projectId),
            method: 'POST',
        });
    }

    public unlink(tagId: number, projectId: TProjectId): Promise<void> {
        return m.request({
            url: TagApi.url_assignment(tagId, projectId),
            method: 'DELETE',
        });
    }
}

const tagApi = new TagApi();
export default tagApi;