import m, {Vnode, VnodeDOM} from "mithril";
import {TTag, TTagsConfig} from "../../../../../../model/api/TagApi";
import Tag from "../../../common/js/Tag";
import translator from "../../../../../model/translator";
import api from "../../../../../../model/api";
import TagAssign from "./TagAssign";

type TOptions = {
    editable: boolean,
    searchTarget: string,
    searchPrefix: string,
    newTagUrl: string,
    projectId?: number|'favourite',
    getConfig: ()=>Promise<TTagsConfig>,
};

export default class TagsAssignmentContainer {

    public tags: Tag[];
    public tagAssign: TagAssign|null = null;

    protected loadingAssign = false;
    protected unlinkingTags: TTag[] = [];

    public config?: TTagsConfig;
    public element?: Element;
    public self;

    constructor(
        tags: TTag[],
        public readonly options: TOptions
    ) {
        this.self = this;
        this.tags = tags.map(tag => new Tag(tag));
    }

    public async openTagAssign(): Promise<void> {
        this.loadingAssign = true;
        m.redraw()
        this.config = await this.options.getConfig();
        this.tagAssign = new TagAssign(this, this.tags.map(t => t.tag));
        this.loadingAssign = false;
        m.redraw();
    }

    public closeTagAssign(): void {
        this.tagAssign = null;
        m.redraw();
    }

    protected addTagToSearchTarget(tag: TTag) {
        const $target = $(this.options.searchTarget);

        if ($target.length) {
            const query = this.options.searchPrefix + tag.search;
            const targetValue = String( $target.val() );

            if ( !targetValue.includes(query) ) {
                $target.val(targetValue+' '+query);
                $target.trigger('keyup');
            }
        }
    }

    protected async unlink(tag: TTag) {
        this.unlinkingTags.push(tag);
        try {
            await api.tag.unlink(tag.id, this.options.projectId);
            this.tags = this.tags.filter(tagComponent => tagComponent.tag !== tag);
        } catch (err) {
            console.warn('ERROR tag_cannot_unlink: '+(err.response?.error ?? ''));
            alert(translator.translate('tag_cannot_unlink'));
        } finally {
            this.unlinkingTags = this.unlinkingTags.filter(t => t !== tag);
            m.redraw();
        }
    }

    protected viewUnlink(tag:TTag): Vnode|null {
        if (!this.options.editable || !this.options.projectId || !tag.id) {
            return null;
        }
        return m('.tag-unlink', {
            title: translator.translate('tag_unlink'),
            onclick: async e => {
                e.stopPropagation();
                if (confirm(translator.translate('tag_unlink_are_you_sure', {tag: tag.name}))) {
                    await this.unlink(tag);
                }
            }
        }, '⨉');
    }

    protected viewPlusAssignTag(): Vnode|null {
        if (!this.options.editable || !this.options.projectId) {
            return null;
        }

        return m('button.btn.btn-sm.btn-outline-success.d-inline-flex.tag-add-button'+(this.loadingAssign?'.disabled':'')+(this.tagAssign?'.active':''), {
            title: translator.translate('tag_assign'),
            onclick: async e => {
                e.preventDefault();
                if (this.tagAssign) {
                    this.closeTagAssign();
                } else {
                    await this.openTagAssign();
                }
                m.redraw();
            },
        }, [
            this.loadingAssign ? m('i.spinner-border.spinner-border-sm') : m('i.icon.ico-tag-plus'),
        ]);
    }

    public oncreate = (vnode: VnodeDOM) => this.element = vnode.dom;

    protected viewTag(tag: Tag): Vnode {
        const disabled = this.tagAssign || this.unlinkingTags.includes(tag.tag);
        return m('.Tag'+(disabled?'.disabled':''), {
            onclick: () => this.addTagToSearchTarget(tag.tag),
        }, [
            m(tag),
            tag.tag.deletable ? this.viewUnlink(tag.tag) : null,
        ])
    }

    protected viewTags(): Vnode {
        return m('.tags', {}, [
            ...this.tags.map(tag => this.viewTag(tag)),
        ]);
    }

    protected viewNoTags(): Vnode {
        return m('.tags');
    }

    public view(): Vnode {
        let bodyOnClickListener;
        return m('.TagsAssignmentContainer'+((this.tagAssign || this.loadingAssign)?'.opened':''), {
            onkeydown: e => {
                if (e.key === 'Escape') {
                    this.closeTagAssign();
                }
            },
            oncreate: () => {
                const self = this;
                bodyOnClickListener = (e: MouseEvent) => {
                    if (self.tagAssign && !self.element.contains(<Node>e.target)) {
                        this.closeTagAssign();
                    }
                }
                document.body.addEventListener('click', bodyOnClickListener);
            },
            onremove: () => {
                document.body.removeEventListener('click', bodyOnClickListener);
            },
        }, [
            this.viewPlusAssignTag(),
            this.tags?.length ? this.viewTags() : this.viewNoTags(),
            this.tagAssign ? m(this.tagAssign) : null,
        ]);
    }

}
